<template>
  <print-table v-if="form.id" v-bind="{form,historyRecord,title}">
    <template v-slot:form>
      <template v-for="(item, index) of flattenList">
        <template v-if="item.type != 'qrcode' && item.type != 'uploader' && item.printable">
          <template v-if="item.wx_show">
            <div class="info_item">
              <div>{{ item.label }}</div>
              <div>
                <template v-if="['multiMonthChildren', 'scheduleChildren', 'refundChildren'].includes(item.myChildrenType)">
                  <template v-if="['activityRefundInfo','refundAccountInfo'].includes(item.myName)">
                    <table-parser :value="item" style="width: auto;border: none"></table-parser>
                  </template>
                  <template v-else-if="['accountInfo'].includes(item.myName)">
                    <descriptions-parser :value="item" style="width: auto;border: none"></descriptions-parser>
                  </template>
                  <template v-else>
                    {{ getValue(item) }}
                  </template>
                </template>
                <template v-else>
                  {{ getValue(item) }}
                </template>
              </div>
            </div>
          </template>
        </template>
      </template>
    </template>
  </print-table>
</template>

<script>
import PrintTable from "@/components/print-table";
import TableParser from "@/components/dynamicForm/components/JSONParser/TableParser";
import DescriptionsParser from "@/components/dynamicForm/components/JSONParser/DescriptionsParser";
import {flatten} from "@/components/dynamicForm/utils/utils";
import {getValueByForm} from "./utils/utils";

export default {
  name: "dynamic-print",
  components: {PrintTable, TableParser, DescriptionsParser},
  data(){
    return {
      id:0,
      form:{},
      historyRecord:[],
      title:''
    }
  },
  created(){
    this.id=this.$route.query.id;
    this.getDetail();
  },
  computed: {
    flattenList() {
      if (this.form.content) {
        this.form.content.form.formSetting.forEach((item,index)=>{
          if(item.myType){
            item.children&&item.children.forEach(item1=>{
              item1.wx_show = item.wx_show
            })
          }
        })
        return flatten(this.form.content.form.formSetting);
      } else {
        return [];
      }
    }
  },
  methods:{
    getDetail() {
      this.$api.dynamicForm.getDetail(this.id).then(({ code, data }) => {
        if(code==200){
          this.form = data;
          this.title = data.typeName
          // hasHistory && this.getHistoryActivity();
        }
      })
    },
    getHistoryActivity(id){
      const page = {
        pageNum: 1,
        pageSize: 10,
      };
      const getHistory = () => {
        this.$api.dynamicForm
            .getHistory({
              id,
              ...page,
            })
            .then(({ code, data }) => {
              if (code == 200) {
                const { list, total } = data;
                this.historyRecord = list;
                if (page.pageNum * page.pageSize < total) {
                  page.pageNum++;
                  getHistory();
                }
              }
              if (code == 450) {
                // 该流程暂无历史
              }
            });
      };
      getHistory();
    },
    getValue(item) {
     return getValueByForm(item)
    },
  }

}
</script>

<style scoped lang="scss">


</style>